package core.io

import core.model.ExportResult
import core.model.Feature
import core.model.FeatureConfig
import core.model.Format
import core.model.ImportParams
import core.model.ImportWarning
import core.model.Note
import core.model.Project
import core.model.Tempo
import core.model.TickCounter
import core.model.TimeSignature
import core.model.Track
import core.model.contains
import core.process.pitch.DvNoteWithPitch
import core.process.pitch.DvSegmentPitchRawData
import core.process.pitch.generateForDv
import core.process.pitch.pitchFromDvTrack
import core.process.validateNotes
import core.util.ArrayBufferReader
import core.util.addBlock
import core.util.addInt
import core.util.addList
import core.util.addListBlock
import core.util.addString
import core.util.nameWithoutExtension
import core.util.readAsArrayBuffer
import org.khronos.webgl.Uint8Array
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import org.w3c.files.File
import kotlin.math.max

/**
 * References: https://pypi.org/project/dvfile/
 */
object Dv {

    suspend fun parse(file: File, params: ImportParams): Project {
        val reader = ArrayBufferReader(file.readAsArrayBuffer())
        val warnings = mutableListOf<ImportWarning>()

        // header
        reader.skip(48)

        // tempo
        val tempoCount = reader.readInt()
        var tempos = mutableListOf<Tempo>()
        repeat(tempoCount) {
            val tickPosition = reader.readInt().toLong()
            val bpm = reader.readInt().toDouble() / 100
            tempos.add(Tempo(tickPosition, bpm))
        }

        // time signature
        reader.skip(4)
        val timeSignatureCount = reader.readInt()
        var timeSignatures = mutableListOf<TimeSignature>()
        repeat(timeSignatureCount) {
            val measurePosition = reader.readInt()
            val numerator = reader.readInt()
            val denominator = reader.readInt()
            timeSignatures.add(TimeSignature(measurePosition, numerator, denominator))
        }
        val tickPrefix = getTickPrefix(timeSignatures)
        tempos = tempos.cleanup(tickPrefix, warnings).toMutableList()
        timeSignatures = timeSignatures.cleanup(warnings).toMutableList()

        // tracks
        val trackCount = reader.readInt()
        var tracks = mutableListOf<Track>()
        repeat(trackCount) {
            parseTrack(tickPrefix, tempos, params, reader)?.let { track ->
                tracks.add(track.validateNotes())
            }
        }
        tracks = tracks.mapIndexed { index, track -> track.copy(id = index) }.toMutableList()

        return Project(
            format = format,
            inputFiles = listOf(file),
            name = file.nameWithoutExtension,
            tracks = tracks,
            timeSignatures = timeSignatures,
            tempos = tempos,
            measurePrefix = FIXED_MEASURE_PREFIX,
            importWarnings = warnings,
        )
    }

    private fun parseTrack(
        tickPrefix: Long,
        tempos: List<Tempo>,
        params: ImportParams,
        reader: ArrayBufferReader,
    ): Track? {
        val trackType = reader.readInt()
        if (trackType != 0) {
            skipRestOfInstTrack(reader)
            return null
        }
        val trackName = reader.readString()
        reader.skip(14)

        val notesWithPitch = mutableListOf<DvNoteWithPitch>()
        val segmentCount = reader.readInt()
        val segmentPitchDataList = mutableListOf<DvSegmentPitchRawData>()
        repeat(segmentCount) {
            val segmentStart = reader.readInt()
            reader.readInt() // segment length
            reader.readString() // segment name
            reader.readString() // voice bank name
            reader.skip(4)
            val noteCount = reader.readInt()
            repeat(noteCount) {
                val noteStart = reader.readInt()
                val noteLength = reader.readInt()
                val noteKey = convertNoteKey(reader.readInt())
                reader.skip(4)
                val lyric = reader.readString()
                reader.readString() // lyric in Chinese character
                reader.skip(1)
                val vibratoData = parseNoteVibratoData(reader)
                reader.readBytes()
                reader.skip(18)
                val benDep = reader.readInt()
                val benLen = reader.readInt()
                val porTail = reader.readInt()
                val porHead = reader.readInt()
                reader.readInt()
                reader.readBytes()
                reader.readInt()
                val note = Note(
                    id = 0,
                    key = noteKey,
                    lyric = lyric,
                    tickOn = segmentStart + noteStart - tickPrefix,
                    tickOff = segmentStart + noteStart - tickPrefix + noteLength,
                )
                notesWithPitch.add(
                    DvNoteWithPitch(
                        note = note,
                        benDep = benDep,
                        benLen = benLen,
                        porHead = porHead,
                        porTail = porTail,
                        vibrato = vibratoData,
                    ),
                )
            }
            reader.readBytes()
            if (params.simpleImport) {
                skipPitchData(reader)
            } else {
                segmentPitchDataList.add(parsePitchData(segmentStart - tickPrefix, reader))
            }
            skipRestOfSegment(reader)
        }
        val notesWithPitchValidated = notesWithPitch.validateNotes()
        val pitch = if (params.simpleImport) null else {
            pitchFromDvTrack(segmentPitchDataList, notesWithPitchValidated, tempos)
        }
        return Track(
            id = 0,
            name = trackName,
            notes = notesWithPitchValidated.map { it.note },
            pitch = pitch,
        )
    }

    private fun parseNoteVibratoData(reader: ArrayBufferReader): List<Pair<Int, Int>> {
        reader.readInt() // vibrato block size
        reader.readBytes()
        reader.readBytes()
        reader.readInt() // rendered-vibrato block size
        val pointLength = reader.readInt()
        val data = mutableListOf<Pair<Int, Int>>()
        repeat(pointLength) {
            data.add(reader.readInt() to reader.readInt())
        }
        return data
    }

    private fun parsePitchData(
        tickOffset: Long,
        reader: ArrayBufferReader,
    ): DvSegmentPitchRawData {
        reader.readInt()
        val pointLength = reader.readInt()
        val data = mutableListOf<Pair<Int, Int>>()
        repeat(pointLength) {
            data.add(reader.readInt() to reader.readInt())
        }
        return DvSegmentPitchRawData(tickOffset, data)
    }

    private fun skipPitchData(
        reader: ArrayBufferReader,
    ) {
        reader.readBytes()
    }

    private fun skipRestOfInstTrack(reader: ArrayBufferReader) {
        reader.readBytes()
        reader.skip(14)
        if (reader.readInt() > 0) {
            reader.skip(8)
            reader.readBytes()
            reader.readBytes()
        }
    }

    private fun skipRestOfSegment(reader: ArrayBufferReader) {
        repeat(5) {
            reader.readBytes()
        }
    }

    private fun getTickPrefix(timeSignatures: List<TimeSignature>): Long {
        val counter = TickCounter()
        timeSignatures
            .map { it.copy(measurePosition = it.measurePosition - STARTING_MEASURE_POSITION) }
            .filter { it.measurePosition < FIXED_MEASURE_PREFIX }
            .forEach { counter.goToMeasure(it) }
        counter.goToMeasure(FIXED_MEASURE_PREFIX)
        return counter.tick
    }

    private fun List<TimeSignature>.cleanup(warnings: MutableList<ImportWarning>): List<TimeSignature> {
        val results = this
            .map { it.copy(measurePosition = it.measurePosition - STARTING_MEASURE_POSITION - FIXED_MEASURE_PREFIX) }
            .toMutableList()

        // Delete all time signatures inside prefix, add apply the last as the first
        val firstTimeSignatureIndex = results
            .last { it.measurePosition <= 0 }
            .let { results.indexOf(it) }
        repeat(firstTimeSignatureIndex) {
            val removed = results.removeAt(0)
            warnings.add(ImportWarning.TimeSignatureIgnoredInPreMeasure(removed))
        }
        results[0] = results[0].copy(measurePosition = 0)
        return results
    }

    private fun List<Tempo>.cleanup(tickPrefix: Long, warnings: MutableList<ImportWarning>): List<Tempo> {
        val results = this
            .map { it.copy(tickPosition = it.tickPosition - tickPrefix) }
            .toMutableList()

        // Delete all tempo tags inside prefix, add apply the last as the first
        val firstTempoIndex = results
            .last { it.tickPosition <= 0 }
            .let { results.indexOf(it) }
        repeat(firstTempoIndex) {
            val removed = results.removeAt(0)
            warnings.add(ImportWarning.TempoIgnoredInPreMeasure(removed))
        }
        results[0] = results[0].copy(tickPosition = 0)
        return results
    }

    fun generate(project: Project, features: List<FeatureConfig>): ExportResult {
        val contentBytes = generateContent(project, features)
        val blob = Blob(arrayOf(contentBytes), BlobPropertyBag("application/octet-stream"))
        return ExportResult(blob, format.getFileName(project.name), listOf())
    }

    private fun generateContent(project: Project, features: List<FeatureConfig>): Uint8Array {
        val bytes = mutableListOf<Byte>()
        bytes.addAll(header)
        val tickPrefix = project.timeSignatures.first().ticksInMeasure.toLong() * FIXED_MEASURE_PREFIX
        val mainBlock = mutableListOf<Byte>().apply {
            addAll("ext1ext2ext3ext4ext5ext6ext7".encodeToByteArray().toList())
            addListBlock(generateTempos(project.tempos, tickPrefix))
            addListBlock(generateTimeSignatures(project.timeSignatures))
            addList(project.tracks.map { generateTrack(it, tickPrefix, features) })
        }
        bytes.addBlock(mainBlock)
        return Uint8Array(bytes.toTypedArray())
    }

    private fun generateTempos(tempos: List<Tempo>, tickPrefix: Long): List<List<Byte>> {
        val firstTempo = tempos.first().copy(tickPosition = 0)
        val restOfTempos = tempos.drop(1).map { it.copy(tickPosition = it.tickPosition + tickPrefix) }
        return (listOf(firstTempo) + restOfTempos)
            .map {
                mutableListOf<Byte>().apply {
                    addInt(it.tickPosition.toInt())
                    addInt((it.bpm * 100).toInt())
                }
            }
    }

    private fun generateTimeSignatures(timeSignatures: List<TimeSignature>): List<List<Byte>> {
        val firstTimeSignature = timeSignatures.first().copy(measurePosition = STARTING_MEASURE_POSITION)
        val restOfTimeSignatures = timeSignatures.drop(1).map {
            it.copy(measurePosition = it.measurePosition + FIXED_MEASURE_PREFIX + STARTING_MEASURE_POSITION)
        }
        return (listOf(firstTimeSignature) + restOfTimeSignatures)
            .map {
                mutableListOf<Byte>().apply {
                    addInt(it.measurePosition)
                    addInt(it.numerator)
                    addInt(it.denominator)
                }
            }
    }

    private fun generateTrack(track: Track, tickPrefix: Long, features: List<FeatureConfig>): List<Byte> {
        val segmentBytes = mutableListOf<Byte>().apply {
            addInt(tickPrefix.toInt())
            val lastNoteTickOff = track.notes.lastOrNull()?.tickOff?.toInt() ?: 0
            addInt(max(lastNoteTickOff, MIN_SEGMENT_LENGTH))
            addString(track.name)
            addString("")
            addListBlock(track.notes.map { generateNote(it, features) })
            addAll(segmentDefaultParameterData1)
            if (features.contains(Feature.ConvertPitch)) {
                val pitch = track.pitch?.generateForDv(track.notes)
                if (pitch == null) {
                    addAll(segmentDefaultParameterDataPitch)
                } else {
                    addListBlock(generatePitchPoints(pitch.data))
                }
            } else {
                addAll(segmentDefaultParameterDataPitch)
            }
            addAll(segmentDefaultParameterData2)
        }
        return mutableListOf<Byte>().apply {
            addInt(0)
            addString(track.name)
            add(0x00)
            add(0x00)
            addInt(DEFAULT_VOLUME)
            addInt(0)
            addListBlock(listOf(segmentBytes))
        }
    }

    private fun generateNote(note: Note, features: List<FeatureConfig>): List<Byte> {
        return mutableListOf<Byte>().apply {
            addInt(note.tickOn.toInt())
            addInt(note.length.toInt())
            addInt(convertNoteKey(note.key))
            addInt(0)
            addString(note.lyric)
            addString(note.lyric)
            add(0x00)
            addBlock(
                mutableListOf<Byte>().apply {
                    addListBlock(
                        listOf(
                            mutableListOf<Byte>().apply {
                                addInt(-1)
                                addInt(0)
                            },
                            mutableListOf<Byte>().apply {
                                addInt(100001)
                                addInt(0)
                            },
                        ),
                    )
                    addListBlock(
                        listOf(
                            mutableListOf<Byte>().apply {
                                addInt(-1)
                                addInt(0)
                            },
                            mutableListOf<Byte>().apply {
                                addInt(100001)
                                addInt(0)
                            },
                        ),
                    )
                    addListBlock(
                        listOf(
                            mutableListOf<Byte>().apply {
                                addInt(0)
                                addInt(0)
                            },
                            mutableListOf<Byte>().apply {
                                addInt(1124)
                                addInt(0)
                            },
                        ),
                    )
                },
            )
            addBlock(noteUnknownDataBlock)
            addAll(noteUnknownPhonemes)
            if (features.contains(Feature.ConvertPitch)) {
                repeat(4) { addInt(0) }
            } else {
                addInt(8)
                addInt(5)
                addInt(16)
                addInt(16)
            }
            addInt(-1)
            addString("")
            addInt(-1)
        }
    }

    private fun generatePitchPoints(data: List<Pair<Int, Int>>): List<List<Byte>> = mutableListOf<List<Byte>>().apply {
        data.forEach { point ->
            val pointBytes = mutableListOf<Byte>().apply {
                addInt(point.first)
                addInt(point.second)
            }
            add(pointBytes)
        }
    }

    private fun convertNoteKey(key: Int) = NOTE_KEY_SUM.toInt() - key
    fun convertNoteKey(key: Double) = NOTE_KEY_SUM - key
    private const val NOTE_KEY_SUM = 115.5

    private const val STARTING_MEASURE_POSITION = -3
    private const val FIXED_MEASURE_PREFIX = 4
    private val header = listOf(
        0x53, 0x48, 0x41, 0x52, 0x50, 0x4B, 0x45, 0x59, 0x05, 0x00, 0x00, 0x00,
    ).map { it.toByte() }
    private const val DEFAULT_VOLUME = 30
    private const val MIN_SEGMENT_LENGTH = 480 * 4
    private val segmentDefaultParameterData1 = listOf(
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0x80, 0x00, 0x00, 0x00, 0x01, 0xB0, 0x04, 0x00, 0x80, 0x00, 0x00, 0x00,
    ).map { it.toByte() }
    private val segmentDefaultParameterDataPitch = listOf(
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0xFF, 0xFF, 0xFF, 0xFF, 0x01, 0xB0, 0x04, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
    ).map { it.toByte() }
    private val segmentDefaultParameterData2 = listOf(
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0x80, 0x00, 0x00, 0x00, 0x01, 0xB0, 0x04, 0x00, 0x80, 0x00, 0x00, 0x00,
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0x80, 0x00, 0x00, 0x00, 0x01, 0xB0, 0x04, 0x00, 0x80, 0x00, 0x00, 0x00,
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0x80, 0x00, 0x00, 0x00, 0x01, 0xB0, 0x04, 0x00, 0x80, 0x00, 0x00, 0x00,
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0x80, 0x00, 0x00, 0x00, 0x01, 0xB0, 0x04, 0x00, 0x80, 0x00, 0x00, 0x00,
        0x14, 0x00, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF,
        0x00, 0x00, 0x00, 0x00, 0x01, 0xB0, 0x04, 0x00, 0x00, 0x00, 0x00, 0x00,
    ).map { it.toByte() }
    private val noteUnknownPhonemes = listOf(
        0x00, 0x00, 0x00, 0x80, 0x3F, 0x00, 0x00, 0x00, 0x80, 0x3F, 0x00, 0x00,
        0x80, 0x3F, 0x00, 0x00, 0x80, 0x3F,
    ).map { it.toByte() }
    private val noteUnknownDataBlock = listOf(
        0x00, 0x04, 0x00, 0x00, 0x07, 0xEF, 0x52, 0xB4, 0x79, 0xCA, 0xC6, 0xBB,
        0xD0, 0xD8, 0x46, 0xBC, 0x49, 0x2C, 0x95, 0xBC, 0x98, 0xF1, 0xC6, 0xBC,
        0xAC, 0xBB, 0xF8, 0xBC, 0xEE, 0x44, 0x15, 0xBD, 0x81, 0x2D, 0x2E, 0xBD,
        0xBB, 0x17, 0x47, 0xBD, 0x07, 0x03, 0x60, 0xBD, 0x15, 0xEF, 0x78, 0xBD,
        0xC9, 0xED, 0x88, 0xBD, 0x16, 0x64, 0x95, 0xBD, 0x47, 0xDA, 0xA1, 0xBD,
        0x36, 0x50, 0xAE, 0xBD, 0xB8, 0xC5, 0xBA, 0xBD, 0xA6, 0x3A, 0xC7, 0xBD,
        0xD6, 0xAE, 0xD3, 0xBD, 0x23, 0x22, 0xE0, 0xBD, 0x62, 0x94, 0xEC, 0xBD,
        0x6E, 0x05, 0xF9, 0xBD, 0x8E, 0xBA, 0x02, 0xBE, 0x95, 0xF1, 0x08, 0xBE,
        0xD5, 0x27, 0x0F, 0xBE, 0x2D, 0x5D, 0x15, 0xBE, 0x88, 0x91, 0x1B, 0xBE,
        0xD4, 0xC4, 0x21, 0xBE, 0xFD, 0xF6, 0x27, 0xBE, 0xEF, 0x27, 0x2E, 0xBE,
        0x98, 0x57, 0x34, 0xBE, 0xE6, 0x85, 0x3A, 0xBE, 0xC3, 0xB2, 0x40, 0xBE,
        0x1F, 0xDE, 0x46, 0xBE, 0xE6, 0x07, 0x4D, 0xBE, 0x05, 0x30, 0x53, 0xBE,
        0x5A, 0x56, 0x59, 0xBE, 0xF3, 0x7A, 0x5F, 0xBE, 0xAD, 0x9D, 0x65, 0xBE,
        0x73, 0xBE, 0x6B, 0xBE, 0x37, 0xDD, 0x71, 0xBE, 0xE6, 0xF9, 0x77, 0xBE,
        0x6A, 0x14, 0x7E, 0xBE, 0x5B, 0x16, 0x82, 0xBE, 0x5B, 0x21, 0x85, 0xBE,
        0x2B, 0x2B, 0x88, 0xBE, 0xC5, 0x33, 0x8B, 0xBE, 0x1D, 0x3B, 0x8E, 0xBE,
        0x2B, 0x41, 0x91, 0xBE, 0xE8, 0x45, 0x94, 0xBE, 0x49, 0x49, 0x97, 0xBE,
        0x3F, 0x4B, 0x9A, 0xBE, 0xD0, 0x4B, 0x9D, 0xBE, 0xED, 0x4A, 0xA0, 0xBE,
        0x8C, 0x48, 0xA3, 0xBE, 0xA4, 0x44, 0xA6, 0xBE, 0x2F, 0x3F, 0xA9, 0xBE,
        0x22, 0x38, 0xAC, 0xBE, 0x77, 0x2F, 0xAF, 0xBE, 0x24, 0x25, 0xB2, 0xBE,
        0x20, 0x19, 0xB5, 0xBE, 0x65, 0x0B, 0xB8, 0xBE, 0xE9, 0xFB, 0xBA, 0xBE,
        0x9D, 0xEA, 0xBD, 0xBE, 0x89, 0xD7, 0xC0, 0xBE, 0x9A, 0xC2, 0xC3, 0xBE,
        0xCC, 0xAB, 0xC6, 0xBE, 0x15, 0x93, 0xC9, 0xBE, 0x6C, 0x78, 0xCC, 0xBE,
        0xCB, 0x5B, 0xCF, 0xBE, 0x29, 0x3D, 0xD2, 0xBE, 0x7F, 0x1C, 0xD5, 0xBE,
        0xC4, 0xF9, 0xD7, 0xBE, 0xF1, 0xD4, 0xDA, 0xBE, 0xFF, 0xAD, 0xDD, 0xBE,
        0xE5, 0x84, 0xE0, 0xBE, 0x9B, 0x59, 0xE3, 0xBE, 0x1D, 0x2C, 0xE6, 0xBE,
        0x57, 0xFC, 0xE8, 0xBE, 0x55, 0xCA, 0xEB, 0xBE, 0x04, 0x96, 0xEE, 0xBE,
        0x5E, 0x5F, 0xF1, 0xBE, 0x5C, 0x26, 0xF4, 0xBE, 0xF9, 0xEA, 0xF6, 0xBE,
        0x29, 0xAD, 0xF9, 0xBE, 0xE7, 0x6C, 0xFC, 0xBE, 0x2C, 0x2A, 0xFF, 0xBE,
        0x7A, 0xF2, 0x00, 0xBF, 0x98, 0x4E, 0x02, 0xBF, 0x70, 0xA9, 0x03, 0xBF,
        0xF9, 0x02, 0x05, 0xBF, 0x38, 0x5B, 0x06, 0xBF, 0x25, 0xB2, 0x07, 0xBF,
        0xBD, 0x07, 0x09, 0xBF, 0xFC, 0x5B, 0x0A, 0xBF, 0xDE, 0xAE, 0x0B, 0xBF,
        0x62, 0x00, 0x0D, 0xBF, 0x83, 0x50, 0x0E, 0xBF, 0x3D, 0x9F, 0x0F, 0xBF,
        0x8E, 0xEC, 0x10, 0xBF, 0x72, 0x38, 0x12, 0xBF, 0xE6, 0x82, 0x13, 0xBF,
        0xE6, 0xCB, 0x14, 0xBF, 0x6F, 0x13, 0x16, 0xBF, 0x80, 0x59, 0x17, 0xBF,
        0x12, 0x9E, 0x18, 0xBF, 0x24, 0xE1, 0x19, 0xBF, 0xB0, 0x22, 0x1B, 0xBF,
        0xB8, 0x62, 0x1C, 0xBF, 0x37, 0xA1, 0x1D, 0xBF, 0x28, 0xDE, 0x1E, 0xBF,
        0x8A, 0x19, 0x20, 0xBF, 0x58, 0x53, 0x21, 0xBF, 0x91, 0x8B, 0x22, 0xBF,
        0x30, 0xC2, 0x23, 0xBF, 0x34, 0xF7, 0x24, 0xBF, 0x98, 0x2A, 0x26, 0xBF,
        0x58, 0x5C, 0x27, 0xBF, 0x75, 0x8C, 0x28, 0xBF, 0xEA, 0xBA, 0x29, 0xBF,
        0xB4, 0xE7, 0x2A, 0xBF, 0xD1, 0x12, 0x2C, 0xBF, 0x3E, 0x3C, 0x2D, 0xBF,
        0xF7, 0x63, 0x2E, 0xBF, 0xFA, 0x89, 0x2F, 0xBF, 0x44, 0xAE, 0x30, 0xBF,
        0xD3, 0xD0, 0x31, 0xBF, 0xA3, 0xF1, 0x32, 0xBF, 0xB1, 0x10, 0x34, 0xBF,
        0xFC, 0x2D, 0x35, 0xBF, 0x80, 0x49, 0x36, 0xBF, 0x3B, 0x63, 0x37, 0xBF,
        0x2B, 0x7B, 0x38, 0xBF, 0x4B, 0x91, 0x39, 0xBF, 0x9A, 0xA5, 0x3A, 0xBF,
        0x13, 0xB8, 0x3B, 0xBF, 0xB9, 0xC8, 0x3C, 0xBF, 0x85, 0xD7, 0x3D, 0xBF,
        0x76, 0xE4, 0x3E, 0xBF, 0x8A, 0xEF, 0x3F, 0xBF, 0xBD, 0xF8, 0x40, 0xBF,
        0x0D, 0x00, 0x42, 0xBF, 0x78, 0x05, 0x43, 0xBF, 0xFC, 0x08, 0x44, 0xBF,
        0x95, 0x0A, 0x45, 0xBF, 0x3F, 0x0A, 0x46, 0xBF, 0xFE, 0x07, 0x47, 0xBF,
        0xCC, 0x03, 0x48, 0xBF, 0xA5, 0xFD, 0x48, 0xBF, 0x89, 0xF5, 0x49, 0xBF,
        0x75, 0xEB, 0x4A, 0xBF, 0x67, 0xDF, 0x4B, 0xBF, 0x5E, 0xD1, 0x4C, 0xBF,
        0x55, 0xC1, 0x4D, 0xBF, 0x4B, 0xAF, 0x4E, 0xBF, 0x3E, 0x9B, 0x4F, 0xBF,
        0x2C, 0x85, 0x50, 0xBF, 0x12, 0x6D, 0x51, 0xBF, 0xEF, 0x52, 0x52, 0xBF,
        0xC2, 0x36, 0x53, 0xBF, 0x87, 0x18, 0x54, 0xBF, 0x3C, 0xF8, 0x54, 0xBF,
        0xDD, 0xD5, 0x55, 0xBF, 0x6C, 0xB1, 0x56, 0xBF, 0xE7, 0x8A, 0x57, 0xBF,
        0x4B, 0x62, 0x58, 0xBF, 0x94, 0x37, 0x59, 0xBF, 0xC2, 0x0A, 0x5A, 0xBF,
        0xD3, 0xDB, 0x5A, 0xBF, 0xC5, 0xAA, 0x5B, 0xBF, 0x95, 0x77, 0x5C, 0xBF,
        0x42, 0x42, 0x5D, 0xBF, 0xCC, 0x0A, 0x5E, 0xBF, 0x2F, 0xD1, 0x5E, 0xBF,
        0x6A, 0x95, 0x5F, 0xBF, 0x7B, 0x57, 0x60, 0xBF, 0x60, 0x17, 0x61, 0xBF,
        0x1A, 0xD5, 0x61, 0xBF, 0xA3, 0x90, 0x62, 0xBF, 0xFB, 0x49, 0x63, 0xBF,
        0x21, 0x01, 0x64, 0xBF, 0x14, 0xB6, 0x64, 0xBF, 0xD0, 0x68, 0x65, 0xBF,
        0x56, 0x19, 0x66, 0xBF, 0xA3, 0xC7, 0x66, 0xBF, 0xB6, 0x73, 0x67, 0xBF,
        0x8E, 0x1D, 0x68, 0xBF, 0x28, 0xC5, 0x68, 0xBF, 0x82, 0x6A, 0x69, 0xBF,
        0x9D, 0x0D, 0x6A, 0xBF, 0x77, 0xAE, 0x6A, 0xBF, 0x0D, 0x4D, 0x6B, 0xBF,
        0x60, 0xE9, 0x6B, 0xBF, 0x6C, 0x83, 0x6C, 0xBF, 0x31, 0x1B, 0x6D, 0xBF,
        0xAE, 0xB0, 0x6D, 0xBF, 0xE2, 0x43, 0x6E, 0xBF, 0xCA, 0xD4, 0x6E, 0xBF,
        0x66, 0x63, 0x6F, 0xBF, 0xB4, 0xEF, 0x6F, 0xBF, 0xB4, 0x79, 0x70, 0xBF,
        0x64, 0x01, 0x71, 0xBF, 0xC2, 0x86, 0x71, 0xBF, 0xCF, 0x09, 0x72, 0xBF,
        0x88, 0x8A, 0x72, 0xBF, 0xED, 0x08, 0x73, 0xBF, 0xFB, 0x84, 0x73, 0xBF,
        0xB3, 0xFE, 0x73, 0xBF, 0x13, 0x76, 0x74, 0xBF, 0x1A, 0xEB, 0x74, 0xBF,
        0xC8, 0x5D, 0x75, 0xBF, 0x1B, 0xCE, 0x75, 0xBF, 0x13, 0x3C, 0x76, 0xBF,
        0xAC, 0xA7, 0x76, 0xBF, 0xE9, 0x10, 0x77, 0xBF, 0xC7, 0x77, 0x77, 0xBF,
        0x45, 0xDC, 0x77, 0xBF, 0x63, 0x3E, 0x78, 0xBF, 0x21, 0x9E, 0x78, 0xBF,
        0x7B, 0xFB, 0x78, 0xBF, 0x73, 0x56, 0x79, 0xBF, 0x08, 0xAF, 0x79, 0xBF,
        0x38, 0x05, 0x7A, 0xBF, 0x03, 0x59, 0x7A, 0xBF, 0x6A, 0xAA, 0x7A, 0xBF,
        0x68, 0xF9, 0x7A, 0xBF, 0xFF, 0x45, 0x7B, 0xBF, 0x2E, 0x90, 0x7B, 0xBF,
        0xF5, 0xD7, 0x7B, 0xBF, 0x53, 0x1D, 0x7C, 0xBF, 0x49, 0x60, 0x7C, 0xBF,
        0xD3, 0xA0, 0x7C, 0xBF, 0xF2, 0xDE, 0x7C, 0xBF, 0xA5, 0x1A, 0x7D, 0xBF,
        0xED, 0x53, 0x7D, 0xBF, 0xC9, 0x8A, 0x7D, 0xBF, 0x38, 0xBF, 0x7D, 0xBF,
        0x39, 0xF1, 0x7D, 0xBF, 0xCD, 0x20, 0x7E, 0xBF, 0xF2, 0x4D, 0x7E, 0xBF,
        0xA9, 0x78, 0x7E, 0xBF, 0xF1, 0xA0, 0x7E, 0xBF, 0xCA, 0xC6, 0x7E, 0xBF,
        0x33, 0xEA, 0x7E, 0xBF, 0x2C, 0x0B, 0x7F, 0xBF, 0xB6, 0x29, 0x7F, 0xBF,
        0xCE, 0x45, 0x7F, 0xBF, 0x76, 0x5F, 0x7F, 0xBF, 0xAE, 0x76, 0x7F, 0xBF,
        0x74, 0x8B, 0x7F, 0xBF, 0xC9, 0x9D, 0x7F, 0xBF, 0xAC, 0xAD, 0x7F, 0xBF,
        0x1E, 0xBB, 0x7F, 0xBF, 0x1F, 0xC6, 0x7F, 0xBF, 0xAD, 0xCE, 0x7F, 0xBF,
        0xCA, 0xD4, 0x7F, 0xBF, 0x75, 0xD8, 0x7F, 0xBF, 0xAE, 0xD9, 0x7F, 0xBF,
        0x75, 0xD8, 0x7F, 0xBF, 0xCA, 0xD4, 0x7F, 0xBF, 0xAD, 0xCE, 0x7F, 0xBF,
        0x1F, 0xC6, 0x7F, 0xBF, 0x1E, 0xBB, 0x7F, 0xBF, 0xAC, 0xAD, 0x7F, 0xBF,
        0xC9, 0x9D, 0x7F, 0xBF, 0x74, 0x8B, 0x7F, 0xBF, 0xAE, 0x76, 0x7F, 0xBF,
        0x76, 0x5F, 0x7F, 0xBF, 0xCE, 0x45, 0x7F, 0xBF, 0xB6, 0x29, 0x7F, 0xBF,
        0x2C, 0x0B, 0x7F, 0xBF, 0x33, 0xEA, 0x7E, 0xBF, 0xCA, 0xC6, 0x7E, 0xBF,
        0xF1, 0xA0, 0x7E, 0xBF, 0xA9, 0x78, 0x7E, 0xBF, 0xF2, 0x4D, 0x7E, 0xBF,
        0xCD, 0x20, 0x7E, 0xBF, 0x39, 0xF1, 0x7D, 0xBF, 0x38, 0xBF, 0x7D, 0xBF,
        0xC9, 0x8A, 0x7D, 0xBF, 0xEE, 0x53, 0x7D, 0xBF, 0xA6, 0x1A, 0x7D, 0xBF,
        0xF2, 0xDE, 0x7C, 0xBF, 0xD3, 0xA0, 0x7C, 0xBF, 0x49, 0x60, 0x7C, 0xBF,
        0x55, 0x1D, 0x7C, 0xBF, 0xF7, 0xD7, 0x7B, 0xBF, 0x30, 0x90, 0x7B, 0xBF,
        0x00, 0x46, 0x7B, 0xBF, 0x68, 0xF9, 0x7A, 0xBF, 0x6A, 0xAA, 0x7A, 0xBF,
        0x04, 0x59, 0x7A, 0xBF, 0x39, 0x05, 0x7A, 0xBF, 0x09, 0xAF, 0x79, 0xBF,
        0x75, 0x56, 0x79, 0xBF, 0x7C, 0xFB, 0x78, 0xBF, 0x21, 0x9E, 0x78, 0xBF,
        0x64, 0x3E, 0x78, 0xBF, 0x46, 0xDC, 0x77, 0xBF, 0xC8, 0x77, 0x77, 0xBF,
        0xEA, 0x10, 0x77, 0xBF, 0xAD, 0xA7, 0x76, 0xBF, 0x13, 0x3C, 0x76, 0xBF,
        0x1C, 0xCE, 0x75, 0xBF, 0xC9, 0x5D, 0x75, 0xBF, 0x1C, 0xEB, 0x74, 0xBF,
        0x14, 0x76, 0x74, 0xBF, 0xB4, 0xFE, 0x73, 0xBF, 0xFC, 0x84, 0x73, 0xBF,
        0xED, 0x08, 0x73, 0xBF, 0x89, 0x8A, 0x72, 0xBF, 0xD0, 0x09, 0x72, 0xBF,
        0xC4, 0x86, 0x71, 0xBF, 0x65, 0x01, 0x71, 0xBF, 0xB5, 0x79, 0x70, 0xBF,
        0xB5, 0xEF, 0x6F, 0xBF, 0x67, 0x63, 0x6F, 0xBF, 0xCB, 0xD4, 0x6E, 0xBF,
        0xE3, 0x43, 0x6E, 0xBF, 0xB0, 0xB0, 0x6D, 0xBF, 0x33, 0x1B, 0x6D, 0xBF,
        0x6D, 0x83, 0x6C, 0xBF, 0x61, 0xE9, 0x6B, 0xBF, 0x10, 0x4D, 0x6B, 0xBF,
        0x78, 0xAE, 0x6A, 0xBF, 0x9F, 0x0D, 0x6A, 0xBF, 0x83, 0x6A, 0x69, 0xBF,
        0x29, 0xC5, 0x68, 0xBF, 0x8F, 0x1D, 0x68, 0xBF, 0xB8, 0x73, 0x67, 0xBF,
        0xA6, 0xC7, 0x66, 0xBF, 0x57, 0x19, 0x66, 0xBF, 0xD2, 0x68, 0x65, 0xBF,
        0x15, 0xB6, 0x64, 0xBF, 0x23, 0x01, 0x64, 0xBF, 0xFC, 0x49, 0x63, 0xBF,
        0xA4, 0x90, 0x62, 0xBF, 0x1B, 0xD5, 0x61, 0xBF, 0x63, 0x17, 0x61, 0xBF,
        0x7D, 0x57, 0x60, 0xBF, 0x6C, 0x95, 0x5F, 0xBF, 0x32, 0xD1, 0x5E, 0xBF,
        0xCF, 0x0A, 0x5E, 0xBF, 0x46, 0x42, 0x5D, 0xBF, 0x98, 0x77, 0x5C, 0xBF,
        0xC7, 0xAA, 0x5B, 0xBF, 0xD4, 0xDB, 0x5A, 0xBF, 0xC3, 0x0A, 0x5A, 0xBF,
        0x95, 0x37, 0x59, 0xBF, 0x4C, 0x62, 0x58, 0xBF, 0xE9, 0x8A, 0x57, 0xBF,
        0x6F, 0xB1, 0x56, 0xBF, 0xDF, 0xD5, 0x55, 0xBF, 0x3C, 0xF8, 0x54, 0xBF,
        0x88, 0x18, 0x54, 0xBF, 0xC4, 0x36, 0x53, 0xBF, 0xF2, 0x52, 0x52, 0xBF,
        0x14, 0x6D, 0x51, 0xBF, 0x2E, 0x85, 0x50, 0xBF, 0x40, 0x9B, 0x4F, 0xBF,
        0x4D, 0xAF, 0x4E, 0xBF, 0x56, 0xC1, 0x4D, 0xBF, 0x5F, 0xD1, 0x4C, 0xBF,
        0x6A, 0xDF, 0x4B, 0xBF, 0x78, 0xEB, 0x4A, 0xBF, 0x8C, 0xF5, 0x49, 0xBF,
        0xA8, 0xFD, 0x48, 0xBF, 0xCE, 0x03, 0x48, 0xBF, 0x03, 0x08, 0x47, 0xBF,
        0x44, 0x0A, 0x46, 0xBF, 0x97, 0x0A, 0x45, 0xBF, 0xFE, 0x08, 0x44, 0xBF,
        0x7A, 0x05, 0x43, 0xBF, 0x10, 0x00, 0x42, 0xBF, 0xBF, 0xF8, 0x40, 0xBF,
        0x8B, 0xEF, 0x3F, 0xBF, 0x78, 0xE4, 0x3E, 0xBF, 0x87, 0xD7, 0x3D, 0xBF,
        0xBB, 0xC8, 0x3C, 0xBF, 0x16, 0xB8, 0x3B, 0xBF, 0x9A, 0xA5, 0x3A, 0xBF,
        0x4A, 0x91, 0x39, 0xBF, 0x2D, 0x7B, 0x38, 0xBF, 0x3E, 0x63, 0x37, 0xBF,
        0x82, 0x49, 0x36, 0xBF, 0xFE, 0x2D, 0x35, 0xBF, 0xB4, 0x10, 0x34, 0xBF,
        0xA5, 0xF1, 0x32, 0xBF, 0xD5, 0xD0, 0x31, 0xBF, 0x46, 0xAE, 0x30, 0xBF,
        0xFC, 0x89, 0x2F, 0xBF, 0xFA, 0x63, 0x2E, 0xBF, 0x41, 0x3C, 0x2D, 0xBF,
        0xD4, 0x12, 0x2C, 0xBF, 0xB8, 0xE7, 0x2A, 0xBF, 0xEC, 0xBA, 0x29, 0xBF,
        0x7A, 0x8C, 0x28, 0xBF, 0x5D, 0x5C, 0x27, 0xBF, 0x9B, 0x2A, 0x26, 0xBF,
        0x36, 0xF7, 0x24, 0xBF, 0x33, 0xC2, 0x23, 0xBF, 0x93, 0x8B, 0x22, 0xBF,
        0x5B, 0x53, 0x21, 0xBF, 0x8D, 0x19, 0x20, 0xBF, 0x2A, 0xDE, 0x1E, 0xBF,
        0x39, 0xA1, 0x1D, 0xBF, 0xBB, 0x62, 0x1C, 0xBF, 0xB3, 0x22, 0x1B, 0xBF,
        0x23, 0xE1, 0x19, 0xBF, 0x11, 0x9E, 0x18, 0xBF, 0x82, 0x59, 0x17, 0xBF,
        0x72, 0x13, 0x16, 0xBF, 0xE9, 0xCB, 0x14, 0xBF, 0xE8, 0x82, 0x13, 0xBF,
        0x74, 0x38, 0x12, 0xBF, 0x91, 0xEC, 0x10, 0xBF, 0x40, 0x9F, 0x0F, 0xBF,
        0x86, 0x50, 0x0E, 0xBF, 0x65, 0x00, 0x0D, 0xBF, 0xE1, 0xAE, 0x0B, 0xBF,
        0xFF, 0x5B, 0x0A, 0xBF, 0xC0, 0x07, 0x09, 0xBF, 0x27, 0xB2, 0x07, 0xBF,
        0x3B, 0x5B, 0x06, 0xBF, 0x00, 0x03, 0x05, 0xBF, 0x73, 0xA9, 0x03, 0xBF,
        0x9B, 0x4E, 0x02, 0xBF, 0x7C, 0xF2, 0x00, 0xBF, 0x32, 0x2A, 0xFF, 0xBE,
        0xED, 0x6C, 0xFC, 0xBE, 0x2F, 0xAD, 0xF9, 0xBE, 0xFF, 0xEA, 0xF6, 0xBE,
        0x62, 0x26, 0xF4, 0xBE, 0x64, 0x5F, 0xF1, 0xBE, 0x0A, 0x96, 0xEE, 0xBE,
        0x5B, 0xCA, 0xEB, 0xBE, 0x5D, 0xFC, 0xE8, 0xBE, 0x23, 0x2C, 0xE6, 0xBE,
        0xA1, 0x59, 0xE3, 0xBE, 0xEB, 0x84, 0xE0, 0xBE, 0x05, 0xAE, 0xDD, 0xBE,
        0xF7, 0xD4, 0xDA, 0xBE, 0xCA, 0xF9, 0xD7, 0xBE, 0x85, 0x1C, 0xD5, 0xBE,
        0x2F, 0x3D, 0xD2, 0xBE, 0xD1, 0x5B, 0xCF, 0xBE, 0x72, 0x78, 0xCC, 0xBE,
        0x1B, 0x93, 0xC9, 0xBE, 0xD2, 0xAB, 0xC6, 0xBE, 0xA1, 0xC2, 0xC3, 0xBE,
        0x8E, 0xD7, 0xC0, 0xBE, 0xAB, 0xEA, 0xBD, 0xBE, 0xF0, 0xFB, 0xBA, 0xBE,
        0x6B, 0x0B, 0xB8, 0xBE, 0x27, 0x19, 0xB5, 0xBE, 0x2A, 0x25, 0xB2, 0xBE,
        0x7D, 0x2F, 0xAF, 0xBE, 0x29, 0x38, 0xAC, 0xBE, 0x36, 0x3F, 0xA9, 0xBE,
        0xAB, 0x44, 0xA6, 0xBE, 0x91, 0x48, 0xA3, 0xBE, 0xF3, 0x4A, 0xA0, 0xBE,
        0xD6, 0x4B, 0x9D, 0xBE, 0x46, 0x4B, 0x9A, 0xBE, 0x48, 0x49, 0x97, 0xBE,
        0xEE, 0x45, 0x94, 0xBE, 0x32, 0x41, 0x91, 0xBE, 0x24, 0x3B, 0x8E, 0xBE,
        0xCB, 0x33, 0x8B, 0xBE, 0x32, 0x2B, 0x88, 0xBE, 0x62, 0x21, 0x85, 0xBE,
        0x62, 0x16, 0x82, 0xBE, 0x79, 0x14, 0x7E, 0xBE, 0xF3, 0xF9, 0x77, 0xBE,
        0x44, 0xDD, 0x71, 0xBE, 0x81, 0xBE, 0x6B, 0xBE, 0xB9, 0x9D, 0x65, 0xBE,
        0xFF, 0x7A, 0x5F, 0xBE, 0x68, 0x56, 0x59, 0xBE, 0x13, 0x30, 0x53, 0xBE,
        0xF4, 0x07, 0x4D, 0xBE, 0x2C, 0xDE, 0x46, 0xBE, 0xD1, 0xB2, 0x40, 0xBE,
        0xF3, 0x85, 0x3A, 0xBE, 0xA5, 0x57, 0x34, 0xBE, 0xFC, 0x27, 0x2E, 0xBE,
        0x0A, 0xF7, 0x27, 0xBE, 0xE1, 0xC4, 0x21, 0xBE, 0x95, 0x91, 0x1B, 0xBE,
        0x3A, 0x5D, 0x15, 0xBE, 0xE3, 0x27, 0x0F, 0xBE, 0xA1, 0xF1, 0x08, 0xBE,
        0x8C, 0xBA, 0x02, 0xBE, 0x88, 0x05, 0xF9, 0xBD, 0x7D, 0x94, 0xEC, 0xBD,
        0x3E, 0x22, 0xE0, 0xBD, 0xF1, 0xAE, 0xD3, 0xBD, 0xC0, 0x3A, 0xC7, 0xBD,
        0xD2, 0xC5, 0xBA, 0xBD, 0x50, 0x50, 0xAE, 0xBD, 0x62, 0xDA, 0xA1, 0xBD,
        0x31, 0x64, 0x95, 0xBD, 0xE4, 0xED, 0x88, 0xBD, 0x4B, 0xEF, 0x78, 0xBD,
        0x3D, 0x03, 0x60, 0xBD, 0xEF, 0x17, 0x47, 0xBD, 0xB6, 0x2D, 0x2E, 0xBD,
        0x23, 0x45, 0x15, 0xBD, 0x16, 0xBC, 0xF8, 0xBC, 0x02, 0xF2, 0xC6, 0xBC,
        0xB3, 0x2C, 0x95, 0xBC, 0xA4, 0xD9, 0x46, 0xBC, 0x20, 0xCC, 0xC6, 0xBB,
        0x00, 0x00, 0x00, 0x00, 0x20, 0xCC, 0xC6, 0x3B, 0xA4, 0xD9, 0x46, 0x3C,
        0xB3, 0x2C, 0x95, 0x3C, 0x02, 0xF2, 0xC6, 0x3C, 0x16, 0xBC, 0xF8, 0x3C,
        0x23, 0x45, 0x15, 0x3D, 0xB6, 0x2D, 0x2E, 0x3D, 0xEF, 0x17, 0x47, 0x3D,
        0x3D, 0x03, 0x60, 0x3D, 0x4B, 0xEF, 0x78, 0x3D, 0xE4, 0xED, 0x88, 0x3D,
        0x31, 0x64, 0x95, 0x3D, 0x62, 0xDA, 0xA1, 0x3D, 0x50, 0x50, 0xAE, 0x3D,
        0xD2, 0xC5, 0xBA, 0x3D, 0xC0, 0x3A, 0xC7, 0x3D, 0xF1, 0xAE, 0xD3, 0x3D,
        0x3E, 0x22, 0xE0, 0x3D, 0x7D, 0x94, 0xEC, 0x3D, 0x88, 0x05, 0xF9, 0x3D,
        0x8C, 0xBA, 0x02, 0x3E, 0xA1, 0xF1, 0x08, 0x3E, 0xE3, 0x27, 0x0F, 0x3E,
        0x3A, 0x5D, 0x15, 0x3E, 0x95, 0x91, 0x1B, 0x3E, 0xE1, 0xC4, 0x21, 0x3E,
        0x0A, 0xF7, 0x27, 0x3E, 0xFC, 0x27, 0x2E, 0x3E, 0xA5, 0x57, 0x34, 0x3E,
        0xF3, 0x85, 0x3A, 0x3E, 0xD1, 0xB2, 0x40, 0x3E, 0x2C, 0xDE, 0x46, 0x3E,
        0xF4, 0x07, 0x4D, 0x3E, 0x13, 0x30, 0x53, 0x3E, 0x68, 0x56, 0x59, 0x3E,
        0xFF, 0x7A, 0x5F, 0x3E, 0xB9, 0x9D, 0x65, 0x3E, 0x81, 0xBE, 0x6B, 0x3E,
        0x44, 0xDD, 0x71, 0x3E, 0xF3, 0xF9, 0x77, 0x3E, 0x79, 0x14, 0x7E, 0x3E,
        0x62, 0x16, 0x82, 0x3E, 0x62, 0x21, 0x85, 0x3E, 0x32, 0x2B, 0x88, 0x3E,
        0xCB, 0x33, 0x8B, 0x3E, 0x24, 0x3B, 0x8E, 0x3E, 0x32, 0x41, 0x91, 0x3E,
        0xEE, 0x45, 0x94, 0x3E, 0x48, 0x49, 0x97, 0x3E, 0x46, 0x4B, 0x9A, 0x3E,
        0xD6, 0x4B, 0x9D, 0x3E, 0xF3, 0x4A, 0xA0, 0x3E, 0x91, 0x48, 0xA3, 0x3E,
        0xAB, 0x44, 0xA6, 0x3E, 0x36, 0x3F, 0xA9, 0x3E, 0x29, 0x38, 0xAC, 0x3E,
        0x7D, 0x2F, 0xAF, 0x3E, 0x2A, 0x25, 0xB2, 0x3E, 0x27, 0x19, 0xB5, 0x3E,
        0x6B, 0x0B, 0xB8, 0x3E, 0xF0, 0xFB, 0xBA, 0x3E, 0xAB, 0xEA, 0xBD, 0x3E,
        0x8E, 0xD7, 0xC0, 0x3E, 0xA1, 0xC2, 0xC3, 0x3E, 0xD2, 0xAB, 0xC6, 0x3E,
        0x1B, 0x93, 0xC9, 0x3E, 0x72, 0x78, 0xCC, 0x3E, 0xD1, 0x5B, 0xCF, 0x3E,
        0x2F, 0x3D, 0xD2, 0x3E, 0x85, 0x1C, 0xD5, 0x3E, 0xCA, 0xF9, 0xD7, 0x3E,
        0xF7, 0xD4, 0xDA, 0x3E, 0x05, 0xAE, 0xDD, 0x3E, 0xEB, 0x84, 0xE0, 0x3E,
        0xA1, 0x59, 0xE3, 0x3E, 0x23, 0x2C, 0xE6, 0x3E, 0x5D, 0xFC, 0xE8, 0x3E,
        0x5B, 0xCA, 0xEB, 0x3E, 0x0A, 0x96, 0xEE, 0x3E, 0x64, 0x5F, 0xF1, 0x3E,
        0x62, 0x26, 0xF4, 0x3E, 0xFF, 0xEA, 0xF6, 0x3E, 0x2F, 0xAD, 0xF9, 0x3E,
        0xED, 0x6C, 0xFC, 0x3E, 0x32, 0x2A, 0xFF, 0x3E, 0x7C, 0xF2, 0x00, 0x3F,
        0x9B, 0x4E, 0x02, 0x3F, 0x73, 0xA9, 0x03, 0x3F, 0x00, 0x03, 0x05, 0x3F,
        0x3B, 0x5B, 0x06, 0x3F, 0x27, 0xB2, 0x07, 0x3F, 0xC0, 0x07, 0x09, 0x3F,
        0xFF, 0x5B, 0x0A, 0x3F, 0xE1, 0xAE, 0x0B, 0x3F, 0x65, 0x00, 0x0D, 0x3F,
        0x86, 0x50, 0x0E, 0x3F, 0x40, 0x9F, 0x0F, 0x3F, 0x91, 0xEC, 0x10, 0x3F,
        0x74, 0x38, 0x12, 0x3F, 0xE8, 0x82, 0x13, 0x3F, 0xE9, 0xCB, 0x14, 0x3F,
        0x72, 0x13, 0x16, 0x3F, 0x82, 0x59, 0x17, 0x3F, 0x11, 0x9E, 0x18, 0x3F,
        0x23, 0xE1, 0x19, 0x3F, 0xB3, 0x22, 0x1B, 0x3F, 0xBB, 0x62, 0x1C, 0x3F,
        0x39, 0xA1, 0x1D, 0x3F, 0x2A, 0xDE, 0x1E, 0x3F, 0x8D, 0x19, 0x20, 0x3F,
        0x5B, 0x53, 0x21, 0x3F, 0x93, 0x8B, 0x22, 0x3F, 0x33, 0xC2, 0x23, 0x3F,
        0x36, 0xF7, 0x24, 0x3F, 0x9B, 0x2A, 0x26, 0x3F, 0x5D, 0x5C, 0x27, 0x3F,
        0x7A, 0x8C, 0x28, 0x3F, 0xEC, 0xBA, 0x29, 0x3F, 0xB8, 0xE7, 0x2A, 0x3F,
        0xD4, 0x12, 0x2C, 0x3F, 0x41, 0x3C, 0x2D, 0x3F, 0xFA, 0x63, 0x2E, 0x3F,
        0xFC, 0x89, 0x2F, 0x3F, 0x46, 0xAE, 0x30, 0x3F, 0xD5, 0xD0, 0x31, 0x3F,
        0xA5, 0xF1, 0x32, 0x3F, 0xB4, 0x10, 0x34, 0x3F, 0xFE, 0x2D, 0x35, 0x3F,
        0x82, 0x49, 0x36, 0x3F, 0x3E, 0x63, 0x37, 0x3F, 0x2D, 0x7B, 0x38, 0x3F,
        0x4A, 0x91, 0x39, 0x3F, 0x9A, 0xA5, 0x3A, 0x3F, 0x16, 0xB8, 0x3B, 0x3F,
        0xBB, 0xC8, 0x3C, 0x3F, 0x87, 0xD7, 0x3D, 0x3F, 0x78, 0xE4, 0x3E, 0x3F,
        0x8B, 0xEF, 0x3F, 0x3F, 0xBF, 0xF8, 0x40, 0x3F, 0x0C, 0x00, 0x42, 0x3F,
        0x78, 0x05, 0x43, 0x3F, 0xFC, 0x08, 0x44, 0x3F, 0x95, 0x0A, 0x45, 0x3F,
        0x42, 0x0A, 0x46, 0x3F, 0x00, 0x08, 0x47, 0x3F, 0xCE, 0x03, 0x48, 0x3F,
        0xA8, 0xFD, 0x48, 0x3F, 0x8C, 0xF5, 0x49, 0x3F, 0x78, 0xEB, 0x4A, 0x3F,
        0x6A, 0xDF, 0x4B, 0x3F, 0x5F, 0xD1, 0x4C, 0x3F, 0x56, 0xC1, 0x4D, 0x3F,
        0x4D, 0xAF, 0x4E, 0x3F, 0x40, 0x9B, 0x4F, 0x3F, 0x2E, 0x85, 0x50, 0x3F,
        0x14, 0x6D, 0x51, 0x3F, 0xF2, 0x52, 0x52, 0x3F, 0xC4, 0x36, 0x53, 0x3F,
        0x88, 0x18, 0x54, 0x3F, 0x3D, 0xF8, 0x54, 0x3F, 0xE1, 0xD5, 0x55, 0x3F,
        0x71, 0xB1, 0x56, 0x3F, 0xEB, 0x8A, 0x57, 0x3F, 0x4E, 0x62, 0x58, 0x3F,
        0x97, 0x37, 0x59, 0x3F, 0xC6, 0x0A, 0x5A, 0x3F, 0xD3, 0xDB, 0x5A, 0x3F,
        0xC5, 0xAA, 0x5B, 0x3F, 0x95, 0x77, 0x5C, 0x3F, 0x43, 0x42, 0x5D, 0x3F,
        0xCD, 0x0A, 0x5E, 0x3F, 0x30, 0xD1, 0x5E, 0x3F, 0x6C, 0x95, 0x5F, 0x3F,
        0x7D, 0x57, 0x60, 0x3F, 0x63, 0x17, 0x61, 0x3F, 0x1B, 0xD5, 0x61, 0x3F,
        0xA4, 0x90, 0x62, 0x3F, 0xFC, 0x49, 0x63, 0x3F, 0x23, 0x01, 0x64, 0x3F,
        0x15, 0xB6, 0x64, 0x3F, 0xD2, 0x68, 0x65, 0x3F, 0x57, 0x19, 0x66, 0x3F,
        0xA6, 0xC7, 0x66, 0x3F, 0xB8, 0x73, 0x67, 0x3F, 0x8F, 0x1D, 0x68, 0x3F,
        0x29, 0xC5, 0x68, 0x3F, 0x85, 0x6A, 0x69, 0x3F, 0xA0, 0x0D, 0x6A, 0x3F,
        0x79, 0xAE, 0x6A, 0x3F, 0x10, 0x4D, 0x6B, 0x3F, 0x62, 0xE9, 0x6B, 0x3F,
        0x6E, 0x83, 0x6C, 0x3F, 0x34, 0x1B, 0x6D, 0x3F, 0xAE, 0xB0, 0x6D, 0x3F,
        0xE2, 0x43, 0x6E, 0x3F, 0xCA, 0xD4, 0x6E, 0x3F, 0x66, 0x63, 0x6F, 0x3F,
        0xB4, 0xEF, 0x6F, 0x3F, 0xB4, 0x79, 0x70, 0x3F, 0x65, 0x01, 0x71, 0x3F,
        0xC4, 0x86, 0x71, 0x3F, 0xD0, 0x09, 0x72, 0x3F, 0x89, 0x8A, 0x72, 0x3F,
        0xED, 0x08, 0x73, 0x3F, 0xFC, 0x84, 0x73, 0x3F, 0xB4, 0xFE, 0x73, 0x3F,
        0x14, 0x76, 0x74, 0x3F, 0x1C, 0xEB, 0x74, 0x3F, 0xC9, 0x5D, 0x75, 0x3F,
        0x1C, 0xCE, 0x75, 0x3F, 0x13, 0x3C, 0x76, 0x3F, 0xAD, 0xA7, 0x76, 0x3F,
        0xEA, 0x10, 0x77, 0x3F, 0xC8, 0x77, 0x77, 0x3F, 0x46, 0xDC, 0x77, 0x3F,
        0x66, 0x3E, 0x78, 0x3F, 0x23, 0x9E, 0x78, 0x3F, 0x7E, 0xFB, 0x78, 0x3F,
        0x76, 0x56, 0x79, 0x3F, 0x0A, 0xAF, 0x79, 0x3F, 0x3B, 0x05, 0x7A, 0x3F,
        0x03, 0x59, 0x7A, 0x3F, 0x68, 0xAA, 0x7A, 0x3F, 0x67, 0xF9, 0x7A, 0x3F,
        0x00, 0x46, 0x7B, 0x3F, 0x30, 0x90, 0x7B, 0x3F, 0xF7, 0xD7, 0x7B, 0x3F,
        0x55, 0x1D, 0x7C, 0x3F, 0x49, 0x60, 0x7C, 0x3F, 0xD3, 0xA0, 0x7C, 0x3F,
        0xF2, 0xDE, 0x7C, 0x3F, 0xA6, 0x1A, 0x7D, 0x3F, 0xEE, 0x53, 0x7D, 0x3F,
        0xC9, 0x8A, 0x7D, 0x3F, 0x38, 0xBF, 0x7D, 0x3F, 0x39, 0xF1, 0x7D, 0x3F,
        0xCD, 0x20, 0x7E, 0x3F, 0xF2, 0x4D, 0x7E, 0x3F, 0xA9, 0x78, 0x7E, 0x3F,
        0xF1, 0xA0, 0x7E, 0x3F, 0xCA, 0xC6, 0x7E, 0x3F, 0x33, 0xEA, 0x7E, 0x3F,
        0x2C, 0x0B, 0x7F, 0x3F, 0xB6, 0x29, 0x7F, 0x3F, 0xCE, 0x45, 0x7F, 0x3F,
        0x76, 0x5F, 0x7F, 0x3F, 0xAE, 0x76, 0x7F, 0x3F, 0x74, 0x8B, 0x7F, 0x3F,
        0xC9, 0x9D, 0x7F, 0x3F, 0xAC, 0xAD, 0x7F, 0x3F, 0x1E, 0xBB, 0x7F, 0x3F,
        0x1F, 0xC6, 0x7F, 0x3F, 0xAD, 0xCE, 0x7F, 0x3F, 0xCA, 0xD4, 0x7F, 0x3F,
        0x75, 0xD8, 0x7F, 0x3F, 0xAE, 0xD9, 0x7F, 0x3F, 0x75, 0xD8, 0x7F, 0x3F,
        0xCA, 0xD4, 0x7F, 0x3F, 0xAD, 0xCE, 0x7F, 0x3F, 0x1F, 0xC6, 0x7F, 0x3F,
        0x1E, 0xBB, 0x7F, 0x3F, 0xAC, 0xAD, 0x7F, 0x3F, 0xC9, 0x9D, 0x7F, 0x3F,
        0x74, 0x8B, 0x7F, 0x3F, 0xAE, 0x76, 0x7F, 0x3F, 0x76, 0x5F, 0x7F, 0x3F,
        0xCE, 0x45, 0x7F, 0x3F, 0xB6, 0x29, 0x7F, 0x3F, 0x2C, 0x0B, 0x7F, 0x3F,
        0x32, 0xEA, 0x7E, 0x3F, 0xC9, 0xC6, 0x7E, 0x3F, 0xF0, 0xA0, 0x7E, 0x3F,
        0xA8, 0x78, 0x7E, 0x3F, 0xF1, 0x4D, 0x7E, 0x3F, 0xCC, 0x20, 0x7E, 0x3F,
        0x38, 0xF1, 0x7D, 0x3F, 0x38, 0xBF, 0x7D, 0x3F, 0xC9, 0x8A, 0x7D, 0x3F,
        0xEE, 0x53, 0x7D, 0x3F, 0xA6, 0x1A, 0x7D, 0x3F, 0xF2, 0xDE, 0x7C, 0x3F,
        0xD3, 0xA0, 0x7C, 0x3F, 0x49, 0x60, 0x7C, 0x3F, 0x55, 0x1D, 0x7C, 0x3F,
        0xF7, 0xD7, 0x7B, 0x3F, 0x30, 0x90, 0x7B, 0x3F, 0x00, 0x46, 0x7B, 0x3F,
        0x68, 0xF9, 0x7A, 0x3F, 0x6A, 0xAA, 0x7A, 0x3F, 0x03, 0x59, 0x7A, 0x3F,
        0x38, 0x05, 0x7A, 0x3F, 0x08, 0xAF, 0x79, 0x3F, 0x73, 0x56, 0x79, 0x3F,
        0x7B, 0xFB, 0x78, 0x3F, 0x20, 0x9E, 0x78, 0x3F, 0x63, 0x3E, 0x78, 0x3F,
        0x45, 0xDC, 0x77, 0x3F, 0xC7, 0x77, 0x77, 0x3F, 0xE9, 0x10, 0x77, 0x3F,
        0xAC, 0xA7, 0x76, 0x3F, 0x12, 0x3C, 0x76, 0x3F, 0x1B, 0xCE, 0x75, 0x3F,
        0xC7, 0x5D, 0x75, 0x3F, 0x19, 0xEB, 0x74, 0x3F, 0x14, 0x76, 0x74, 0x3F,
        0xB4, 0xFE, 0x73, 0x3F, 0xFC, 0x84, 0x73, 0x3F, 0xED, 0x08, 0x73, 0x3F,
        0x89, 0x8A, 0x72, 0x3F, 0xD0, 0x09, 0x72, 0x3F, 0xC4, 0x86, 0x71, 0x3F,
        0x65, 0x01, 0x71, 0x3F, 0xB5, 0x79, 0x70, 0x3F, 0xB5, 0xEF, 0x6F, 0x3F,
        0x66, 0x63, 0x6F, 0x3F, 0xCA, 0xD4, 0x6E, 0x3F, 0xE2, 0x43, 0x6E, 0x3F,
        0xAE, 0xB0, 0x6D, 0x3F, 0x31, 0x1B, 0x6D, 0x3F, 0x6C, 0x83, 0x6C, 0x3F,
        0x60, 0xE9, 0x6B, 0x3F, 0x0D, 0x4D, 0x6B, 0x3F, 0x77, 0xAE, 0x6A, 0x3F,
        0x9D, 0x0D, 0x6A, 0x3F, 0x81, 0x6A, 0x69, 0x3F, 0x26, 0xC5, 0x68, 0x3F,
        0x8C, 0x1D, 0x68, 0x3F, 0xB5, 0x73, 0x67, 0x3F, 0xA2, 0xC7, 0x66, 0x3F,
        0x55, 0x19, 0x66, 0x3F, 0xCF, 0x68, 0x65, 0x3F, 0x13, 0xB6, 0x64, 0x3F,
        0x23, 0x01, 0x64, 0x3F, 0xFC, 0x49, 0x63, 0x3F, 0xA4, 0x90, 0x62, 0x3F,
        0x1B, 0xD5, 0x61, 0x3F, 0x63, 0x17, 0x61, 0x3F, 0x7D, 0x57, 0x60, 0x3F,
        0x6C, 0x95, 0x5F, 0x3F, 0x31, 0xD1, 0x5E, 0x3F, 0xCE, 0x0A, 0x5E, 0x3F,
        0x43, 0x42, 0x5D, 0x3F, 0x95, 0x77, 0x5C, 0x3F, 0xC5, 0xAA, 0x5B, 0x3F,
        0xD3, 0xDB, 0x5A, 0x3F, 0xC2, 0x0A, 0x5A, 0x3F, 0x94, 0x37, 0x59, 0x3F,
        0x4B, 0x62, 0x58, 0x3F, 0xE7, 0x8A, 0x57, 0x3F, 0x6C, 0xB1, 0x56, 0x3F,
        0xDD, 0xD5, 0x55, 0x3F, 0x39, 0xF8, 0x54, 0x3F, 0x85, 0x18, 0x54, 0x3F,
        0xC0, 0x36, 0x53, 0x3F, 0xED, 0x52, 0x52, 0x3F, 0x10, 0x6D, 0x51, 0x3F,
        0x29, 0x85, 0x50, 0x3F, 0x3B, 0x9B, 0x4F, 0x3F, 0x48, 0xAF, 0x4E, 0x3F,
        0x57, 0xC1, 0x4D, 0x3F, 0x60, 0xD1, 0x4C, 0x3F, 0x6A, 0xDF, 0x4B, 0x3F,
        0x78, 0xEB, 0x4A, 0x3F, 0x8C, 0xF5, 0x49, 0x3F, 0xA8, 0xFD, 0x48, 0x3F,
        0xCE, 0x03, 0x48, 0x3F, 0x01, 0x08, 0x47, 0x3F, 0x43, 0x0A, 0x46, 0x3F,
        0x95, 0x0A, 0x45, 0x3F, 0xFC, 0x08, 0x44, 0x3F, 0x78, 0x05, 0x43, 0x3F,
        0x0D, 0x00, 0x42, 0x3F, 0xBD, 0xF8, 0x40, 0x3F, 0x8A, 0xEF, 0x3F, 0x3F,
        0x76, 0xE4, 0x3E, 0x3F, 0x85, 0xD7, 0x3D, 0x3F, 0xB9, 0xC8, 0x3C, 0x3F,
        0x13, 0xB8, 0x3B, 0x3F, 0x98, 0xA5, 0x3A, 0x3F, 0x48, 0x91, 0x39, 0x3F,
        0x27, 0x7B, 0x38, 0x3F, 0x38, 0x63, 0x37, 0x3F, 0x7E, 0x49, 0x36, 0x3F,
        0xFA, 0x2D, 0x35, 0x3F, 0xAE, 0x10, 0x34, 0x3F, 0xA0, 0xF1, 0x32, 0x3F,
        0xD0, 0xD0, 0x31, 0x3F, 0x47, 0xAE, 0x30, 0x3F, 0xFD, 0x89, 0x2F, 0x3F,
        0xFB, 0x63, 0x2E, 0x3F, 0x41, 0x3C, 0x2D, 0x3F, 0xD4, 0x12, 0x2C, 0x3F,
        0xB8, 0xE7, 0x2A, 0x3F, 0xED, 0xBA, 0x29, 0x3F, 0x78, 0x8C, 0x28, 0x3F,
        0x5A, 0x5C, 0x27, 0x3F, 0x98, 0x2A, 0x26, 0x3F, 0x34, 0xF7, 0x24, 0x3F,
        0x30, 0xC2, 0x23, 0x3F, 0x91, 0x8B, 0x22, 0x3F, 0x58, 0x53, 0x21, 0x3F,
        0x8A, 0x19, 0x20, 0x3F, 0x28, 0xDE, 0x1E, 0x3F, 0x37, 0xA1, 0x1D, 0x3F,
        0xB8, 0x62, 0x1C, 0x3F, 0xB0, 0x22, 0x1B, 0x3F, 0x21, 0xE1, 0x19, 0x3F,
        0x0F, 0x9E, 0x18, 0x3F, 0x7C, 0x59, 0x17, 0x3F, 0x6C, 0x13, 0x16, 0x3F,
        0xE3, 0xCB, 0x14, 0x3F, 0xE3, 0x82, 0x13, 0x3F, 0x6E, 0x38, 0x12, 0x3F,
        0x8A, 0xEC, 0x10, 0x3F, 0x3A, 0x9F, 0x0F, 0x3F, 0x86, 0x50, 0x0E, 0x3F,
        0x66, 0x00, 0x0D, 0x3F, 0xE2, 0xAE, 0x0B, 0x3F, 0xFF, 0x5B, 0x0A, 0x3F,
        0xC0, 0x07, 0x09, 0x3F, 0x29, 0xB2, 0x07, 0x3F, 0x3C, 0x5B, 0x06, 0x3F,
        0xFC, 0x02, 0x05, 0x3F, 0x70, 0xA9, 0x03, 0x3F, 0x98, 0x4E, 0x02, 0x3F,
        0x7A, 0xF2, 0x00, 0x3F, 0x2C, 0x2A, 0xFF, 0x3E, 0xE7, 0x6C, 0xFC, 0x3E,
        0x29, 0xAD, 0xF9, 0x3E, 0xF9, 0xEA, 0xF6, 0x3E, 0x5C, 0x26, 0xF4, 0x3E,
        0x5E, 0x5F, 0xF1, 0x3E, 0x04, 0x96, 0xEE, 0x3E, 0x55, 0xCA, 0xEB, 0x3E,
        0x57, 0xFC, 0xE8, 0x3E, 0x15, 0x2C, 0xE6, 0x3E, 0x95, 0x59, 0xE3, 0x3E,
        0xDE, 0x84, 0xE0, 0x3E, 0xF7, 0xAD, 0xDD, 0x3E, 0xEA, 0xD4, 0xDA, 0x3E,
        0xBD, 0xF9, 0xD7, 0x3E, 0x78, 0x1C, 0xD5, 0x3E, 0x30, 0x3D, 0xD2, 0x3E,
        0xD2, 0x5B, 0xCF, 0x3E, 0x74, 0x78, 0xCC, 0x3E, 0x1B, 0x93, 0xC9, 0x3E,
        0xD4, 0xAB, 0xC6, 0x3E, 0xA2, 0xC2, 0xC3, 0x3E, 0x90, 0xD7, 0xC0, 0x3E,
        0xA5, 0xEA, 0xBD, 0x3E, 0xE9, 0xFB, 0xBA, 0x3E, 0x65, 0x0B, 0xB8, 0x3E,
        0x20, 0x19, 0xB5, 0x3E, 0x24, 0x25, 0xB2, 0x3E, 0x77, 0x2F, 0xAF, 0x3E,
        0x22, 0x38, 0xAC, 0x3E, 0x2F, 0x3F, 0xA9, 0x3E, 0xA4, 0x44, 0xA6, 0x3E,
        0x8C, 0x48, 0xA3, 0x3E, 0xED, 0x4A, 0xA0, 0x3E, 0xD0, 0x4B, 0x9D, 0x3E,
        0x3F, 0x4B, 0x9A, 0x3E, 0x41, 0x49, 0x97, 0x3E, 0xDF, 0x45, 0x94, 0x3E,
        0x24, 0x41, 0x91, 0x3E, 0x15, 0x3B, 0x8E, 0x3E, 0xBD, 0x33, 0x8B, 0x3E,
        0x24, 0x2B, 0x88, 0x3E, 0x53, 0x21, 0x85, 0x3E, 0x54, 0x16, 0x82, 0x3E,
        0x7B, 0x14, 0x7E, 0x3E, 0xF4, 0xF9, 0x77, 0x3E, 0x48, 0xDD, 0x71, 0x3E,
        0x83, 0xBE, 0x6B, 0x3E, 0xBB, 0x9D, 0x65, 0x3E, 0x03, 0x7B, 0x5F, 0x3E,
        0x6A, 0x56, 0x59, 0x3E, 0x05, 0x30, 0x53, 0x3E, 0xE6, 0x07, 0x4D, 0x3E,
        0x1F, 0xDE, 0x46, 0x3E, 0xC3, 0xB2, 0x40, 0x3E, 0xE6, 0x85, 0x3A, 0x3E,
        0x98, 0x57, 0x34, 0x3E, 0xEF, 0x27, 0x2E, 0x3E, 0xFD, 0xF6, 0x27, 0x3E,
        0xD4, 0xC4, 0x21, 0x3E, 0x88, 0x91, 0x1B, 0x3E, 0x2D, 0x5D, 0x15, 0x3E,
        0xD5, 0x27, 0x0F, 0x3E, 0x95, 0xF1, 0x08, 0x3E, 0x7E, 0xBA, 0x02, 0x3E,
        0x4E, 0x05, 0xF9, 0x3D, 0x42, 0x94, 0xEC, 0x3D, 0x04, 0x22, 0xE0, 0x3D,
        0xB6, 0xAE, 0xD3, 0x3D, 0x86, 0x3A, 0xC7, 0x3D, 0x99, 0xC5, 0xBA, 0x3D,
        0x16, 0x50, 0xAE, 0x3D, 0x67, 0xDA, 0xA1, 0x3D, 0x35, 0x64, 0x95, 0x3D,
        0xE9, 0xED, 0x88, 0x3D, 0x56, 0xEF, 0x78, 0x3D, 0x48, 0x03, 0x60, 0x3D,
        0xFA, 0x17, 0x47, 0x3D, 0xC1, 0x2D, 0x2E, 0x3D, 0xEE, 0x44, 0x15, 0x3D,
        0xAC, 0xBB, 0xF8, 0x3C, 0x98, 0xF1, 0xC6, 0x3C, 0x49, 0x2C, 0x95, 0x3C,
        0xD0, 0xD8, 0x46, 0x3C, 0x79, 0xCA, 0xC6, 0x3B,
    ).map { it.toByte() }

    private val format = Format.Dv
}
